<template>
  <div>
    <div class="employment-message">
      <FrameBrowser 
        :id="id"
        :imageURL="url"
        :x="Number(x)"
        :y="Number(y)"
        :code="code"
        @close="closeModal"
      />
    </div>
  </div>
</template>

<script>
import FrameBrowser from '@/components/Organisms/FrameBrowser'

export default {
  name: 'FrameViewer',
  components: {
      FrameBrowser
  },
  props: {
    id: String,
    x: String,
    y: String,
    code: String,
  },
  data() {
    this.url = `${process.env.VUE_APP_IMAGES_URL}artwork/${this.id}/${this.id}_1.jpg`;
    return { hover: false }
  },
  methods: {
    closeModal() {
      this.$router.push(`/${this.$store.state.lang}/`)
    }
  }
};
</script>
<style scoped lang="scss">
.employment-message {
  display: flex;
  align-items: center;
  img {
    max-width: 50%;
  }
}
</style>
