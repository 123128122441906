<template>
  <NeoModal
    max-width="1000px"
    width="100%"
    :title-header="$t('yourFrames')"
    @close="closeModal"
    content-class="frames-work-host"
    @clickOutsideCallback="closeModal"
  >
    <v-card-text ref="modal">
      <div class="frames-dialog" v-if="dataLoaded">
        <div class="frames-dialog__canvaswrap">
          <frames-work
            height-wrap="100%"
            :info="workInfo"
            v-if="dataLoaded"
            :id="id"
            :img="imageURL"
            :coordinates="[x, y]"
          />
        </div>
        <div class="frames-dialog__aside">
          <!-- <div class="frames-dialog__aside-title">
            <p class="frames-dialog__text">{{ $t('frameBrowserHeader') }}</p>
            <p class="frames-dialog__text">{{ $t('frameBrowserHeader2') }}</p>
          </div> -->
          <div class="frames-dialog__thumb">
            <!-- <tippy class="frames-tippy" arrow arrow-type="round" theme="dark">
              <template v-slot:trigger>
                <button
                  :class="{ 'arrow-disabled': framePointer <= 0 }"
                  :disabled="framePointer <= 0"
                  type="button"
                  @click="moveLeft"
                >
                  <v-icon color="base" size="32">mdi-chevron-left</v-icon>
                </button>
              </template>
              {{ $t('previous') }}
            </tippy> -->
            <div class="frames-dialog__frame">
              <img :src="url" />
              <div class="ml-2 frames-dialog__frametext">
                <div>{{ $t("frame") }} {{ framePointer + 1 }}:</div>
                <div>
                  <span>(of {{ frames.length }})</span>
                </div>
                <div class="mt-4"><strong>X</strong>: {{ x }}</div>
                <div><strong>Y</strong>: {{ y }}</div>
              </div>
            </div>
            <!-- <tippy class="frames-tippy" arrow arrow-type="round" theme="dark">
              <template v-slot:trigger>
                <button
                  :class="{
                    'arrow-disabled': framePointer === frames.length - 1,
                  }"
                  :disabled="framePointer === frames.length - 1"
                  type="button"
                  @click="moveRight"
                >
                  <v-icon color="base" size="32">mdi-chevron-right</v-icon>
                </button>
              </template>
              {{ $t('next') }}
            </tippy> -->
          </div>
          <div class="d-flex justify-center mt-2 mb-2">
            <v-btn
              large
              :disabled="disabled || waiting"
              color="primary"
              @click="startClaim"
            >
              {{ $t("claimFrame") }}
              <v-icon size="32" v-if="waiting" class="ml-1 waiting">
                mdi-tire
              </v-icon>
            </v-btn>
          </div>
          <VueRecaptcha
            :sitekey="recaptchaKey"
            @verify="onVerify"
            @expired="onExpired"
            ref="recaptcha"
            size="invisible"
            badge="inline"
            type="contact"
            :loadRecaptchaScript="false"
            theme="light"
          ></VueRecaptcha>
        </div>
        <!-- <div
          class="frames-dialog__canvaswrap"
          :style="`--cssAspectRatio: ${cssAspectRatio}; --aspect-padding-top: ${
            cssAspectRatio * 100
          }%`"
        > -->
      </div>
    </v-card-text>
  </NeoModal>
</template>

<script>
import FramesDS from "@/services/FramesDS";
import helpers from "@/mixins/helpers";

import FramesWork from "@/components/Organisms/FramesWork";
import NeoModal from "@/components/Molecules/NeoModal";

import { VueRecaptcha } from "vue-recaptcha";
import { firebase } from "@/helpers/firebase";

export default {
  name: "FramesBrowser",
  mixins: [helpers],
  components: {
    FramesWork,
    NeoModal,
    VueRecaptcha,
  },
  props: {
    id: String,
    x: Number,
    y: Number,
    code: String,
    imageURL: String,
    initialCoordinate: Array,
    coordinateIndex: { type: Number, default: 0 },
  },
  data() {
    return {
      disabled: false,
      waiting: false,
      scale: 10,
      dataLoaded: false,
      frames: [],
      framePointer: 0,
      recaptchaKey: process.env.VUE_APP_RECAPTCHA_SITE,
      url: "",
      frameWidth: 0,
      frameHeight: 0,
      workInfo: {},
    };
  },
  methods: {
    startClaim() {
      this.$refs.recaptcha.execute();
    },
    populateFrameData(x, y) {
      this.x = x;
      this.y = y;
      this.url = `${process.env.VUE_APP_IMAGES_URL}Frames%2F${this.id}%2F${
        this.id
      }_${this.leftFillNum(x, 3)}_${this.leftFillNum(y, 3)}.jpg`;
    },
    closeModal() {
      this.$emit("close");
    },
    onVerify(response) {
      if (response !== "") {
        this.claimFrame();
      } else {
        return false;
      }
    },
    onExpired() {
      this.resetRecaptcha();
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset();
      this.disabled = true;
    },
    claimFrame() {
      this.waiting = true;
      firebase
        .app()
        .functions("asia-northeast1")
        .httpsCallable("frames-claimFrame")({
          id: this.id,
          x: this.x,
          y: this.y,
          code: this.code,
        })
        .then((res) => {
          console.log(res);
          this.waiting = false;
          if (res.data.status === 0) {
            console.log("ok");
            this.$router.push(`/${this.$store.state.lang}/summary/collection`)
          } else {
            console.log("not ok ", res.data.status);
            this.closeModal();
          }
        })
        .catch((error) => {
          // Getting the Error details.
          this.waiting = false;
          console.log(error);
          this.closeModal();
        });
    },
  },
  beforeMount() {
    // this.image = new Image();
    // this.image.src = this.imageURL;

    // this.image.onload = function () {
    //   this.ratio = this.image.width / this.image.height;
    //   this.cssAspectRatio = this.ratio;
    // }.bind(this);

    // hasta aqui

    const data = FramesDS.getCoordinateInfo(this.id);
    this.framePointer = this.coordinateIndex;
    data.then(
      function (snapshot) {
        if (snapshot.exists()) {
          const snap = snapshot.val();
          this.ratio = snap.ratio;
          this.workInfo = snap;
          this.frameWidth = snap.nX;
          this.frameHeight = snap.nY;
          this.dataLoaded = true;
        }
      }.bind(this)
    );
    // Get frames from
    this.frames = [0];
    this.populateFrameData(this.x, this.y);
    // const frames = FramesDS.getUserArtworkFrames(
    //   this.$store.state.user.uid,
    //   this.id
    // );
    // if (this.frameList.length > 0) {
    //   this.frames = this.frameList;
    //   const x = this.initialCoordinate[0];
    //   const y = this.initialCoordinate[1];

    //   this.populateFrameData(x, y);
    // } else {
    //   frames.then(
    //     function (snapshot) {
    //       if (snapshot.exists()) {
    //         const snap = snapshot.val();
    //         // let index = 0;
    //         for (let id in snap) {
    //           snap.id = id;
    //           this.frames.push(snap[id]);
    //         }
    //         const x = this.frames[this.framePointer].x;
    //         const y = this.frames[this.framePointer].y;
    //         this.populateFrameData(x, y);
    //       }
    //       // Get frames from
    //     }.bind(this)
    //   );
    // }
  },
};
</script>

<style lang="scss">
.grecaptcha-badge {
  visibility: hidden;
  position: absolute;
}
.reCAPTCHA-disclaimer {
  margin-top: 15px;
  font-size: var(--font-size-super-small);
}
.parent {
  width: 100%;
  height: auto;
}
.frames-work-host {
  height: 100%;
  overflow: hidden;
  @media only screen and (min-width: 2000px) {
    max-height: 950px !important;
  }
}
.frames-work-host .neomodal {
  height: 100%;
}
.frames-work-host .neomodal .v-card__text {
  height: calc(100% - 50px);
  padding: 0 !important;
}

.frames-dialog {
  height: 100%;
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
  &__aside {
    order: 1;
    @media only screen and (min-width: 768px) {
      order: 0;
      max-width: 275px;
    }
  }
  &__aside-title {
    padding: 8px;
  }
  &__text {
    margin-bottom: 0px !important;
    text-align: center;
  }
}

.frames-dialog__thumb {
  padding: 8px;
  background: var(--color-grey);
  display: flex;
  align-items: center;
  justify-content: center;
}

.frames-dialog__canvaswrap {
  flex-grow: 1;
  position: relative;
  height: 100%;
  padding: 8px;
  background: var(--color-grey);
  // padding-top: var(--aspect-padding-top);
  canvas {
    border: 1px solid var(--color-grey-dark);
    width: auto !important;
    max-width: 90%;
    max-height: 90%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 8px);
    box-shadow: var(--shadow1-over);
    @media only screen and (min-width: 768px) {
      transform: translate(-50%, 16px);
    }
    @media only screen and (min-width: 2000px) {
      max-height: 80%;
    }
  }
}

.frames-dialog__frame {
  display: flex;
  align-items: center;
  color: var(--color-black);
  line-height: 1;
  padding: 8px;
  @media only screen and (max-width: 767px) {
    background-color: var(--color-white);
  }
  span {
    font-size: 0.85em;
  }
  img {
    min-width: 100px;
    min-height: 100px;
    border: 1px solid var(--color-black);
    box-shadow: var(--shadow1);
    background-image: linear-gradient(
        -90deg,
        rgba(225, 229, 233, 1) 38%,
        rgba(171, 173, 173, 1) 61%
      ),
      linear-gradient(
        -90deg,
        rgba(171, 173, 173, 1) 61%,
        rgba(225, 229, 233, 1) 38%
      );
    background-size: 200% auto;
    background-position: 0 0;
    animation: loading 1s infinite alternate-reverse;
    @media (max-width: 600px) {
      min-width: 75px;
      min-height: 75px;
    }
  }
}
.frames-dialog__frametext {
  width: 110px;
}

@keyframes loading {
  to {
    background-position: 75% 0;
  }
}

.arrow-disabled {
  opacity: 0.3;
}
</style>
