export default {
    methods: {
      leftFillNum(num, targetLength) {
        if (num) return num.toString().padStart(targetLength, 0);
        else return null;
      },
      formatAmount(currency, amount){
        if (!currency || !amount) return;
        let numberFormat = new Intl.NumberFormat(["en-US"], {
          style: "currency",
          currency: currency,
          currencyDisplay: "symbol",
        });
        return numberFormat.format(amount);
      }
    },
  };
  